"use client";
import React, { useState, useEffect } from "react";
import {Product_iovia_detail_img, Product_fr_img} from "@/public";
import Image from "next/image";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import Link from "next/link";

type Props = {};

const slides_img = [Product_fr_img, Product_iovia_detail_img];
const texts = [
  {
    title: "Flash Report",
    paragraph:
      "Optimisez votre gestion de projet avec Flash Report : IA et algorithmes pour une efficacité accrue, adapté à toute méthodologie.",
  },
  {
    title: "Iovia",
    paragraph:
      "Découvrez IOVIA, l'application gratuite pour trouver votre métier idéal dans l'IT selon vos compétences et profil, disponible sur Google Play",
  },
  // {
  //   title: "En savoir plus",
  //   paragraph:
  //     "Explorez nos produits exclusifs, soigneusement sélectionnés pour offrir des solutions inédites. Soyez à l’affut des dernières innovations Cyde.",
  // },
];

const features_19 = (props: Props) => {
  const [position, setPosition] = useState(0);
  const autoSlide = false;
  const autoSlideInterval = 3000;

  const [curr, setCurr] = useState(0);

  const prev = () =>
    setCurr((curr) => (curr === 0 ? slides_img.length - 1 : curr - 1));

  const next = () =>
    setCurr((curr) => (curr === slides_img.length - 1 ? 0 : curr + 1));

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, []);

  return (
    <div className="bg-[#F9F7F1] w-full h-full flex flex-col lg:flex-row md:flew-col justify-center items-start gap-16 px-6 md:px-12 lg:px-32 relative pt-8 md:pt-16 lg:pt-24 pb-8 md:pb-16">
      <div className="h-full lg:h-[500px] w-full lg:w-[500px] rounded-xl">
        {/* Carousel */}
        <div className="overflow-hidden relative rounded-xl">
          <div
            className="flex transition-transform ease-out duration-500 h-full lg:h-[500px] w-full lg:w-[500px]"
            style={{ transform: `translateX(-${curr * 100}%)` }}
          >
            {[
              ...slides_img.map((s, index) => (
                <Image
                  src={s}
                  alt="slider product"
                  className="w-full h-full object-contain object-center"
                  key={index}
                />
              )),
            ]}
          </div>
          <div className="absolute inset-0 flex items-center justify-between p-4">
            <button
              onClick={prev}
              className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
            >
              <MdChevronLeft className="text-white hover:text-black" />
            </button>
            <button
              onClick={next}
              className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
            >
              <MdChevronRight className="text-white hover:text-black" />
            </button>
          </div>
          <div className="absolute bottom-4 right-0 left-0">
            <div className="flex items-center justify-center gap-2">
              {slides_img.map((s: any, i: number) => (
                <div
                  key={i}
                  className={`transition-all w-1.5 h-1.5 bg-white rounded-full  ${
                    curr === i ? "p-0.5" : "bg-opacity-50"
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
        {/* end Carousel */}
      </div>
      <div className="flex flex-col justify-center items-start gap-8 w-full lg:w-1/2 h-full">
        <h3 className="font-bold text-xl text-yellow-800 leading-[1.1]">
          Découvrez nos solutions innovantes
        </h3>
        {texts.map((text, index) => (
          <div
            className={`${index === curr ? "flex flex-col gap-4" : "hidden"}`}
            key={index}
          >
            <h3 className="font-bold text-3xl md:text-6xl leading-[1.1] text-black  pb-0 md:pb-8">
              {text.title}
            </h3>
            <p className="text-black/60 text-muted-foreground md:text-lg/relaxed text-gray-900">
              {text.paragraph}
            </p>
          </div>
        ))}

        <Link
          href="/products"
          className="inline-flex h-10 w-48 items-center justify-center rounded-md bg-black px-8 text-sm font-medium text-white shadow"
        >
          En savoir plus
        </Link>
      </div>
    </div>
  );
};

export default features_19;
